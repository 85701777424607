import React from "react";
import '../../css/faq.css';
import { Row, Col, Button } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion'

const FAQ = (props) => {
    const accordionData = [
        {
            "id": "1",
            "Title": "Risus sed odio ut enim blandit ac turpis egestas sed tempus ?",
            "Discription": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        },
        {
            "id": "2",
            "Title": "Risus sed odio ut enim blandit ac turpis egestas sed tempus ?",
            "Discription": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        },
        {
            "id": "3",
            "Title": "Risus sed odio ut enim blandit ac turpis egestas sed tempus ?",
            "Discription": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        },
        {
            "id": "4",
            "Title": "Risus sed odio ut enim blandit ac turpis egestas sed tempus ?",
            "Discription": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        }
    ]
    return (
        <>
            <div className="faqConatainer">
                <div className="sectionTitle">
                    FAQS
                </div>
                <div className="titleUnderline">
                    <div className="thickLine faq-underline"></div>
                    <div className="thinLine"></div>
                </div>
                <div className="faqAccordion">
                    <Accordion alwaysOpen>
                        {props.data && props.data.map((item, index) => {
                            return (
                                <Accordion.Item eventKey={index+"sdad"} key={index}>
                                    <Accordion.Header>{item.question}</Accordion.Header>
                                    <Accordion.Body>
                                        {item.answer}
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })}
                    </Accordion>
                </div>
            </div>
        </>
    );
}

export default FAQ;