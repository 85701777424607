import React from "react";
import stars from '../../images/stars.svg'

const GetCertificate=(props) =>{


    return(
    <div className="container-margin-bottom" style={{marginBottom:"100px"}}> 
    <div className="d-flex ms-5 certificate-heading-row ">
            <img  src={stars} className="certificate-heading-icon desktop-view"/>
            <div className="certificate-heading">
                <div className="certificate-heading-title font-family-Helvetica">Yes! You will be certified once you complete this course.</div>
                <div className="certificate-sub-heading">Do use this certificate to enhance your professional credibility and stand out among your peers!</div>
            </div>
    </div>
            <div className="certificate-display">
                    <img src={props.data.dummy_certificate} className="certificate"></img>
            </div>
        </div>);
}

export default GetCertificate;