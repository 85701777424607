import moment from 'moment';
import React, { useState } from 'react';
import '../../css/batches.css';
import { Row,Col } from 'react-bootstrap'; 

const Batches = (props) => {

    const [selectedBatch, setSelectedBatches] = useState(props.batchIndex);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const days = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];

    const getMonth = (data) => {
        let month = moment(data, 'YYYY/MM/DD').format('M');
        return months[month - 1];
    }
    const getDay = (data) => {
        let day = new Date(data).getDay();
        return days[day];
    }
    const getBatchTime = (data) => {
        let startTime = moment(data).format("h:mm a");
        let endTime = moment(data).add(parseInt(props.sessionInfo.duration), 'minutes');
        endTime = moment(endTime).format("h:mm a");
        return startTime + " - " + endTime;
    }
    const isDisabled = (data) => {
        if (data.seats_available > 0 && new Date(data?.start_date) > new Date()) {
            return '';
        }
        else {
            return 'disabled_batch';
        }
    }
    const selectBatch = (item) => {
        if (item.seats_available > 0) {
            props.selectBatch(item);
            props.batchInformation(item, props.sessionInfo);
            props.checkSeatsAvailability(item);
            props.CourseAvailability(item);
        }
    }
    return (
        <>
            {props.batchData && props.batchData.length > 0 &&
                < div className='batch_container  container-margin-bottom'>
                    <div className="sectionTitle">
                        AVAILABLE BATCHES
                    </div>
                    <div className="titleUnderline">
                        <div className="thickLine available-batches-underline"></div>
                        <div className="thinLine"></div>
                    </div>
                    <div className='batch_list'>
                        <Row className=' m-0 d-flex justify-content-between'>
                            {props.batchData.map((item, index) => {
                                return (
                                    <Col md={5} sm={12} className='pl-1 pr-1' key={index}>
                                        <div className={`${index == selectedBatch ? "batch_item active_batch" : "batch_item"} ${isDisabled(item)}`} onClick={() => { setSelectedBatches(index); selectBatch(item); }}>
                                            <div className='batch_date'>{moment(item.start_date, 'YYYY/MM/DD').format('Do')} {getMonth(item.start_date)} {getDay(item.start_date)}</div>
                                            <div className='batch_time font-family-Helvetica'>{getBatchTime(item.start_date)}</div>
                                        </div>
                                    </Col>
                                )
                            })
                            }
                        </Row>
                    </div>
                </div>
            }
        </>
    );
}

export default Batches;