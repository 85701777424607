import React from 'react'
import "../../css/educatorInfo.css"
export default function EducatorInfo(props) {
    return (
        <div className='container-margin-bottom'>
            <div className="sectionTitle">
                {props.title}
            </div>
            <div className="titleUnderline">
                <div className="thickLine about-speaker-underline"></div>
                <div className="thinLine"></div>
            </div>
            <div>
                <div className="course-educator-wrapper" style={{ margin: "10px 0" }}>
                    <div className="course-educator-image"> <img width={500} src={props.data.profile_pic} alt="icon"></img></div>
                    <div>
                        <div className="course-educator-name">{props.data.user_info.first_name}</div>
                        <div className="course-educator-tag">{props.data.tag_line}</div>

                        <div className="course-educator-domain" >
                            {
                                props.data.teaching_domains.map((item, index) => {
                                    return <span className='domain' key={index + 'asdf'} style={{marginRight:"15px"}}>{item} </span>
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="course-educator-desc " style={{ margin: "10px 0"}}>
                <q className="quotes">{props.data.summary}</q>
                </div>
            </div>
        </div>
    )
}
