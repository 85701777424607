import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';
import '../../css/style.css';
import BottomBar from '../BottomBar/BottomBar';
import CourseDetails from '../CourseDetails/CourseDetails';
import FAQ from '../Faq/Faq';
import UpcomingCourses from '../upcomingCourses/upcomingCourses';
import CourseFeedbacks from '../courseFeedbacks/courseFeedbacks';
import AboutCourse from '../AboutCourse/AboutCourse';
import EducatorInfo from '../EducatorInfo/EducatorInfo';
import Batches from '../Batches/Batches';
import { Modal, Button, Form } from 'react-bootstrap';
import moment from 'moment';
import logo from '../../images/BLC_ inverse.png';
import GetCertificate from '../GetCertificate/GetCertificate';
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Alert } from "react-bootstrap";
import DownloadApp from '../../shared/DownloadApp';
import ThankYou from '../thankYou/ThankYou';


const DashboardLayout = () => {
  const search = useLocation().search;
  const course_info = new URLSearchParams(search).get('course_info');
  const [courseDetails, setCourseDetails] = useState(null);
  const [feedbackData, setFeedbackData] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [educatorData, setEducatorData] = useState(null);
  const [signInModel, setSignInModel] = useState(false);
  const [show, setShow] = useState(false);
  const [isCheckExistingUser, setCheckExistingUser] = useState(false);
  const[isCheckWhatsAppNotification,setCheckWhatsAppNotification]=useState(true)
  const [seatsAvailability, setSeatsAvailability] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("+91");
  const [name, setName] = useState("");
  const [otp, setOtp] = useState(null);
  const [upcomingCourseData, setUpcomingCourseData] = useState([]);
  const [upcomingWorkshopData, setUpcomingWorkshopData] = useState([]);
  const [batchesData, setBatchesData] = useState([]);
  const [sessionInfo, setSessionInfo] = useState([]);
  const [courseFrequency, setCourseFrequency] = useState([]);
  const [batchStartDateTime, setBatchStartDateTime] = useState("");
  const [timerData, setTimerData] = useState();
  const [timeLeft, setTimeLeft] = useState({ day: 0, hour: 0, minute: 0, second: 0 });
  const [selectedCourseDetails, setSelectedCourseDetails] = useState({
    startDate: "",
    time: ""
  });
  const [orderId, setOrderId] = useState(null);
  const [userData, setUserData] = useState([]);
  const [finalFees, setFinalFees] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [selectedBatchId , setSelectedBatchId] = useState(0);
  
  const [otpValidation,setOtpValidation] = useState(true);
  const [errorText,setErrorText]=useState("");

  const[alertVariant, setAlertVariant]=useState("danger");
  const[alertHeading, setAlertHeading]=useState("");
  const[alertMessage, setAlertMessage]=useState("");
  const [alertShow, setAlertShow] = useState(false);
  const [batchStartDate, setBatchStartDate] = useState();
  const [SelectedBatchIndex,setSelectedBatchIndex] = useState();


  const handleClose = () => {setShow(false);}
  const handleShow = () => {
    setShow(true);
    setOtp("");
    setName("");
    setPhoneNumber("+91");
    setOtpValidation(true);
    setCheckExistingUser(false);
    setCheckWhatsAppNotification(true);
    setErrorText("");
  }



  useEffect(() => {
    // if(courseDetails.fees == 0)
    //     {
    //       setFinalFees(0);
    //     }
    if (courseDetails && courseDetails.fees > 0 && courseDetails.discount > 0) { setFinalFees(Math.round(courseDetails.fees - (courseDetails.fees / 100) * courseDetails.discount)); }

    if (courseDetails && courseDetails.discount == 0) {
      setFinalFees(courseDetails.fees);
    }
  }, [courseDetails]);




  useEffect(() => {
    if (!courseDetails) {
      getCourseDetails();
    }
  },[]);

  const getCourseDetails = () => {
    axios.get("/page/" + course_info).then(res => {
      setLoading(false);
      if (res.data.data.result.course_info) {

        setCourseDetails({
          video: res.data.data.result.course_info.video,
          type: res.data.data.result.course_info.type,
          title: res.data.data.result.course_info.title,
          thumbnail: res.data.data.result.course_info.thumbnail,
          syllabus: res.data.data.result.course_info.syllabus,
          summary: res.data.data.result.course_info.summary,
          share_link: res.data.data.result.course_info.share_link,
          session_count: res.data.data.result.course_info.session_count,
          level: res.data.data.result.course_info.level,
          language: res.data.data.result.course_info.language,
          id: res.data.data.result.course_info.id,
          fees: res.data.data.result.course_info.fees,
          dummy_certificate: res.data.data.result.course_info.dummy_certificate,
          discount: res.data.data.result.course_info.discount,
          batch_info: res.data.data.result.course_info.batch_info,
          session_info: res.data.data.result.course_info.session_info,
          description:  res.data.data.result.course_info.description,
          show_video: res.data.data.result.course_info.show_video

        });

        setFeedbackData(res.data.data.result.course_info.feedback);
        setFaqs(res.data.data.result.course_info.faq);
        setEducatorData(res.data.data.result.course_info.edu_info);
        setUpcomingCourseData(res.data.data.result.course_info.upcoming_courses);
        setUpcomingWorkshopData(res.data.data.result.course_info.upcoming_workshops);
        setBatchesData(res.data.data.result.course_info.batch_info);
        setSessionInfo(res.data.data.result.course_info.session_info);
        if (res?.data?.data?.result?.course_info?.batch_info?.length > 0) {
          let index = res?.data?.data?.result?.course_info?.batch_info.findIndex((x,index) => (new moment(x.start_date) > new moment() && x.seats_available>0));
          
          if(index == -1)
          {
            index=0;
          }
          setTimerData(res?.data?.data?.result?.course_info?.batch_info[index]);
          batchInformation(res?.data?.data?.result?.course_info?.batch_info[index], res.data.data.result.course_info.session_info);
          checkSeatsAvailability(res?.data?.data?.result?.course_info?.batch_info[index]);
          CourseAvailability(res?.data?.data?.result?.course_info?.batch_info[index]);
          setSelectedBatchId(res?.data?.data?.result?.course_info?.batch_info[index].id)
          setSelectedBatchIndex(index)
        }
      }
    }).catch(error => {
      console.log(error);
    }
    );
  }



  const handleChange = (e) => {
    setCheckExistingUser(e.target.checked);
    setErrorText("");    
  }
  
  const handleChangeWhatsApp = (e) => {
    setCheckWhatsAppNotification(e.target.checked);
    setErrorText("");
    
  }

  const handleChangeName = (e) => {
    setName(e.target.value);
    
    setErrorText("");
  }


  const handleChangePhone = (e) => {
    setPhoneNumber(e.target.value);
 
    setErrorText("");
  }

  const handleChangeOtp = (e) => {
    setOtp(e.target.value);
   
    setErrorText("");
  }




  /////////////////////////////////////////////////////////////////////////////////////////////////
  //SingIn Functions
////////////////////////////////////////////////////////////////////////////////////////////////
  const sendOtp = () => {
    setPhoneNumber(phoneNumber);
    let data = {
      username:  phoneNumber,
      password: "XjLVN6oOLc",
    }
    axios.defaults.headers.common = {
      'Authorization': '',
      'DEVICE-UDID': ''
    }

    axios.post("/users/signin_otp/", data).then(res => {
      if(res.data.data.result.is_otp_sent_successfully)     {        
        setOtpValidation(false);
      }
      toast(res.data.data.user_message);

    }).catch(error => {
      if (error.response.status==400) {              
        setErrorText(error.response.data.data.user_message);
        setOtpValidation(true); 
        setOtp(""); 
      }      
    });
  }

  const getAccessToken = () => {
    let data = {
      username: phoneNumber,
      password: otp,
    };
    let deviceUDID = localStorage.getItem("DEVICE-UDID");
    axios.defaults.headers.common = {
      'DEVICE-UDID': deviceUDID
    }
    axios.post("/users/token/", data).then(res => {
      if (res.data.data.result) {
        localStorage.setItem("UserData", JSON.stringify(res.data.data.result));
        setUserData(res.data.data.result);
        GetOrderId();
      }
    });
  };

  const verifyOtp = () => {
    let data = {
      username: phoneNumber,
      password: otp,
      is_signup: !isCheckExistingUser,
    };
    axios
      .post("/users/verifyotp/", data)
      .then(res => {
        if (res.data.data.result.is_otp_verified) {
          getAccessToken();
        }else{
          setErrorText(res.data.data.user_message);           
          
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response.status==400) {              
          setErrorText(error.response.data.data.user_message);
          setOtpValidation(true); 
          setOtp(""); 
        }
      });
  };


  const signUp = () => {

    axios.defaults.headers.common = {
      'Authorization': '',
      'DEVICE-UDID': ''
    }
    let data = {
      first_name: name,
      user_type: "S",
      username: phoneNumber,
      password: "ksdjfs",
      is_whatsapp_notifications: isCheckWhatsAppNotification
    };
    axios
      .post("/users/signup_otp/", data)
      .then(res => {
        if (res.data.data.result.is_otp_sent_successfully) {
          setOtpValidation(false);  
                
        }
        toast(res.data.data.user_message); 
      })
      .catch(error => {
        console.log(error);
        if (error.response.status==400) {              
          setErrorText(error.response.data.data.user_message);
          setOtpValidation(true); 
          setOtp(""); 
        }
      });

  }
  const GetOtp = () => {

    if (isCheckExistingUser) {
      sendOtp();
    } else {
      signUp();
    }
  }


  const ReSendOtp = () => {
    let data = {
      username: phoneNumber,
      password: "dummy",
      is_signup: !isCheckExistingUser,
    };
    axios
      .post("users/resendotp/", data)
      .then(res => {
        if (res.data.data.result.is_otp_sent_successfully) {
          //setOtpValidation(false);  
              
        }
        toast(res.data.data.user_message);   
      })
      .catch(error => {
        console.log(error);
        if (error.response.status==400) {              
          setErrorText(error.response.data.data.user_message);          
          setOtp(""); 
        }
      });

  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(timerData));
    }, 1000);

    return () => clearTimeout(timer);
  });

  const calculateTimeLeft = (data) => {
    if (moment(data?.start_date) > moment()) {
      const then = moment(data?.start_date);
      const now = moment();
      const countdown = moment(then).diff(moment(now));      
      var d = moment.duration(countdown);      
      const days = moment(then).diff(moment(now),'days');
      const hours = d._data.hours;
      const minutes = d._data.minutes;
      const seconds = d._data.seconds;

      return { day: days, hour: hours, minute: minutes, second: seconds };
    }
    else {
      return { day: 0, hour: 0, minute: 0, second: 0 };
    }
  }

  const selectBatch = (data) => {
    setTimerData(data);
    batchInformation(data, sessionInfo);
    CourseAvailability(data);
    setSelectedBatchId(data.id)
  }

  const batchInformation = (data, sessInfo) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    let month = moment(data.start_date, 'YYYY/MM/DD').format('M');
    month = months[month - 1];
    let startTime = moment(data.start_date).format("h:mm a");
      var tempDate = moment(data.start_date).format('Do MMM @ h:mm a');
    setBatchStartDateTime(tempDate)
    let start = moment(data.start_date).utcOffset(0, true).format("h:mm a");
    let endTime = moment(data.start_date).add(parseInt(sessInfo.duration), 'minutes');
    endTime = moment(endTime).format("h:mm a");
    setSelectedCourseDetails({
      startDate: moment(data.start_date, 'YYYY/MM/DD').format('Do') + " " + month,
      time: start + " - " + endTime
    })
  }

  const checkSeatsAvailability = (item) => {
    setBatchStartDate(item.start_date);
 
    if (item.seats_available > 0) {
      setSeatsAvailability(true);
    }else {
      setSeatsAvailability(false);
    }
  }

  const CourseAvailability = (data) => {
    let tmp = [];
    if (data && data.frequency && data.frequency.length > 0) {
      data.frequency.forEach(element => {
        let day = new Date(element).getDay();
        tmp.push(parseInt(day));
      });
      setCourseFrequency(tmp);
    }
  }



  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /// Razor pay functionality 
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  const GetOrderId = () => {
    handleClose();
    let tempUserData =JSON.parse(localStorage.getItem("UserData"));
    if(!tempUserData){
      handleShow();
      return;
    }
    let deviceUDID = localStorage.getItem("DEVICE-UDID");
    axios.defaults.headers.common = {
      'Authorization': `Bearer ${tempUserData.access}`,
      'DEVICE-UDID': deviceUDID
    }

    var Provider = "NA"
    if (finalFees > 0) {
      Provider = "R"
    }
    let data = {
      batch_info: selectedBatchId,
      stu_info: tempUserData.id,
      amount: finalFees,
      currency: "INR",
      notes: "student",
      provider: Provider,
      source:"W"
    };

    axios
      .post("/course/batch/enrol/", data)
      .then(res => {
        if (res.data.data.result.is_order_id_created) {
          setOrderId(res.data.data.result.order_id);
          if(finalFees==0){            
          setAlertVariant("success");
          setAlertHeading("Oh Great! Enrollment successful");
          setAlertMessage("");
          setAlertShow(true);
          }         
        }else{
          setAlertVariant("danger");
          setAlertHeading(res.data.data.user_message);
          setAlertShow(true);
        }
      })
      .catch(error => {       
        
        if (error.response.status==400) {
          setAlertVariant("danger");
          setAlertHeading(error.response.data.data.user_message);          
          setAlertShow(true);
        }else{
          handleShow();
        }
      });

  }


  useEffect(() =>{
    if(finalFees > 0){
        displayRazorpay();
    }
  },[orderId]);
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const handleKeyDownGetOTP=(e) => {
    if (e.key === 'Enter') {
      GetOtp();
    }
  }

  const handleKeyDownVerifyOTP=(e) => {
    if (e.key === 'Enter') {
      verifyOtp();
    }
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const options = {
      key: process.env.REACT_APP_RAZORPAY_APP_ID_prod, // Enter the Key ID generated from the Dashboard
      amount: finalFees*100,
      currency: "INR",
      name: "CoachX",
      description: "CoachX",
      image: { logo },
      order_id: orderId,
      handler: async function (response) {
        setAlertVariant("success");
        setAlertHeading("Oh Great! Enrollment successful");
        setAlertMessage("");
        setAlertShow(true);
        const data = {
          orderCreationId: orderId,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        const result = await axios.post("http://localhost:5000/payment/success", data);
        alert(result.data.msg);
      },
      prefill: {
        name: userData.first_name,
        contact: userData.username,
      },
      notes: {
        source: "LandingPage",
        BatchId:selectedBatchId,
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    

    
  }

  return (
    <>
      <div className='main-dashboard'>
        <Header />
        <ToastContainer 
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        />
       
        {isLoading && <div className="loader-div"> <div className="loader"></div></div>}
        {!isLoading && <>
          {courseDetails && <CourseDetails data={courseDetails} signInModel={GetOrderId} courseFrequency={courseFrequency} selectedCourseDetails={selectedCourseDetails}  seatsAvailability={seatsAvailability} batchStartDate={batchStartDate} />}

          <div className='container custom-container'>
            {courseDetails && <AboutCourse data={courseDetails} />}
            {educatorData && <EducatorInfo data={educatorData} title="ABOUT THE SPEAKER" />}
            {courseDetails && <GetCertificate data={courseDetails} />}
            {batchesData && <Batches batchData={batchesData} sessionInfo={sessionInfo} batchIndex={SelectedBatchIndex} selectBatch={selectBatch} batchInformation={batchInformation} CourseAvailability={CourseAvailability} checkSeatsAvailability={checkSeatsAvailability} />}
            {feedbackData.length > 0 && <CourseFeedbacks data={feedbackData} title="COURSE FEEDBACK" btntitle="Register now" />}
            
            <DownloadApp/>

            {faqs.length > 0 && <FAQ data={faqs} />}

            {upcomingWorkshopData.length > 0 && <UpcomingCourses data={upcomingWorkshopData} title="Upcoming Free Workshops" className="UpcomingFreeWorkshops" btntitle="Register for free" />}

            {upcomingCourseData.length > 0 && <UpcomingCourses data={upcomingCourseData} title="Upcoming Full Course" className="UpcomingFullCourse" btntitle="Register now" />}
          {/* <button onClick={()=>{setAlertVariant("success");    setAlertShow(true); }}> model open</button> */}
          </div>
          {courseDetails && <BottomBar data={courseDetails} signInModel={GetOrderId} timeLeft={timeLeft} batchStartDateTime={batchStartDateTime} seatsAvailability={seatsAvailability} batchStartDate={batchStartDate} />}
        </>}
      </div>

      <Modal 
      show={alertShow} 
      onHide={() =>setAlertShow(false)}
      centered
      > 
      {   alertVariant === "danger" &&
        <Alert variant={alertVariant} onClose={() => setAlertShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
          </Alert>
        }
        {alertVariant === "success" &&
          <> 
          <div className="success-card" >
             <div className='success-checkbox' >
          <i class="checkmark">✓</i>
            </div>
          <h1 className="success-heading">Success</h1> 
          <p className='success-text'>Thank you for registering for this course. Request you to join 5 mins before your scheduled time through CoachX app or login on <a className='mobile-view' target="_blank" href='https://play.google.com/store/apps/details?id=com.classboat.coachx'> CoachX.Live</a>
          <a className='desktop-view' target="_blank" href='https://coachxsession.web.app/'>CoachX.Live</a> and attend the course from your dashboard.</p>
          </div>
          </>
        }     
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="font-24 font-family-Helvetica">Enroll and start learning today</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {!isCheckExistingUser &&
            <Form.Group className="mb-3" >
              <Form.Label className="font-20 font-family-Helvetica">Name<span className="error">*</span></Form.Label>
              <Form.Control className="font-20 py-2"type="text" onChange={e => handleChangeName(e)} placeholder="Enter your profile name" />
            </Form.Group>
          }
          <Form.Group className="mb-3" >
            <Form.Label className="font-20 font-family-Helvetica">Mobile No.</Form.Label>
            <Form.Control className="font-20 py-2" type="tel" value={phoneNumber} maxLength="13" onChange={e => handleChangePhone(e)} onKeyPress={handleKeyDownGetOTP}  placeholder="Eg: +918745300223 (Enter WhatsApp number)" />
            {otpValidation &&<div className='errorMessage'>{errorText}</div>}
            {!otpValidation &&<div className="text-right" onClick={ReSendOtp}><span className='sendotp-link'>Resend OTP</span></div>}
          </Form.Group>
          { !otpValidation && 
          <Form.Group className="mb-3" >
            <Form.Label className="font-20 font-family-Helvetica">OTP</Form.Label>
            <Form.Control className="font-20 py-2" type="password" value={otp}  maxLength="6" onChange={e => handleChangeOtp(e)} onKeyPress={handleKeyDownVerifyOTP} placeholder="******" />
            <div className='errorMessage'>{errorText}</div>
          </Form.Group>
          }
          { otpValidation &&
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" onChange={e => handleChange(e)} label="Existing user?  Sign In" />
          </Form.Group>}
          {!isCheckExistingUser && otpValidation &&
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" onChange={e => handleChangeWhatsApp(e)} checked={isCheckWhatsAppNotification} label="Receive WhatsApp notifications?" />
          </Form.Group>}
          {otpValidation && 
          <Button onClick={GetOtp} variant="primary" className="modelbtn" type="submit">
            Get OTP
          </Button>}
          {!otpValidation && 
          <Button onClick={verifyOtp} variant="primary" className="modelbtn" type="submit">
          </Button>
            }
        </Modal.Body>
      </Modal>
    </>

  );
}

export default DashboardLayout;