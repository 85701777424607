import React from 'react'
import img1 from '../images/img1.PNG'
import img2 from '../images/img2.PNG'
import img3 from '../images/img3.PNG'
import googlePLayStore from '../images/google-play-badge.png'
import  "../css/downloadApp.css"

export default function DownloadApp() {
  return (
    <div className=' wrapper container-fluid'>

      <div className='row'>
      <div className='col-md-5 overflow-hidden left'>
        <div className='left-wrapper'>
        <p  className="h1">Learn <span className="h2">LIVE</span> From  <p className='p1'>BEST TEACHERS</p></p>
        <img  src={img3} alt='Image' className="img3"/>
        </div>
      </div>
      <div className='col-md-7  d-flex align-items-center flex-direction-column justify-content-between'>
      {/* <div className='border '> */}
        <img className='img1 ' src={img1} alt='Image'/>
      {/* </div> */}
      <div className='d-flex justify-content-space-evenly align-items-center'>
        <strong className='download-app me-2'>Download App Now!</strong> 
        <a href='https://play.google.com/store/apps/details?id=com.classboat.coachx'><img src={googlePLayStore} alt='google play store' className='me-2'/></a>
      </div>
      </div>

    </div>
    </div>
  )
}
