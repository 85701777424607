import React from 'react';
import logo from '../../images/BLC_ inverse.png';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

const Header= () =>{
    return(
        <Navbar className='dark' variant="dark">
            <Container>
                <Navbar.Brand target="_self" href="https://coachx.live/" >
                    <img
                    alt=""
                    src={logo}                  
                    className="d-inline-block align-top logo-size"/>{' '}
                </Navbar.Brand>
            </Container>
        </Navbar>
    );
}

export default Header;