import React from 'react'
import CourseItem from '../../shared/course-item'

import Slider from "react-slick";


export default function UpcomingCourses(props) {

    var settings = {
        dots: false,
        infinite: false,
        arrows:false,
        speed: 200,
        slidesToShow: 2.5,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className={`container-margin-bottom ${props.className}`}>
            <div className="sectionTitle">
                {props.title}
            </div>
            <div className="titleUnderline">
                <div className="thickLine courses-underline"></div>
                <div className="thinLine"></div>
            </div>
            <div className="course-container">
                <Slider {...settings}>
                    {
                        props.data.map((item, index) => {
                            return <CourseItem key={index} data={item} btntitle={props.btntitle} />
                        })
                    }
                </Slider>
            </div>

        </div>
    )
}
