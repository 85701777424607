import React, {useEffect, useState} from "react";
import '../../css/bottom-bar.css';
import { Row, Col } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import moment from "moment";

const BottomBar = (props) => {

    var count = 3600 * 3;
    let counter = null;
   /* useEffect(() => {
        counter = setInterval(timer, 2000); //1000 will  run it every 1 second
    }, [])
*/
    const [timerstr, setTimerstr] = useState("");

    function timer() {
        count = count - 1;
        if (count == -1) {
            clearInterval(counter);
            return;
        }

        var seconds = count % 60;
        var minutes = Math.floor(count / 60);
        var hours = Math.floor(minutes / 60);
        minutes %= 60;
        hours %= 60;
        //setTimerstr("00" + "days " + hours + "hours " + minutes + "minute " + seconds + "seconds ");
        document.getElementById("timer").innerHTML = "00 days " + hours + " hours " + minutes + " minutes " + seconds + " seconds"; // watch for spelling
    }
    const [showTerms, setShowTerms] = useState(false);


    const [offerPrize, setOfferPrize]=useState(0);

    useEffect(()=>{
        if(props.data.fees == 0)
        {
            setOfferPrize(0);
        }
        if(props.data.fees > 0 && props.data.discount>0)
        {
            setOfferPrize(Math.round(props.data.fees-((props.data.fees/100)*props.data.discount)));
        }
        if(props.data.discount == 0){
            setOfferPrize(props.data.fees);
        }
    },[]);
    return (
        <>
            <div className='footer-starts'></div>
            <div className="footer-container">
                <div className='row m-0'>
                    <div className='col-md-4 col-12'>
                        <div className='footer_content'>
                            <div>Copyright  © 2022</div>
                            <div>All rights are reserved by</div>
                            <div>CoachX.live</div>
                        </div>
                    </div>
                    <div className='col-md-4 col-12'>
                        <div className='footer_content'>
                            <div className='policy_and_term' onClick={() => { setShowTerms(true); }}>
                                <div className="curser-pointer">Privacy Policies</div>
                                <div className="curser-pointer"> Terms & Conditions</div>
                                <div className="curser-pointer"> Refunds/Cancellations</div>
                            </div>
                            <div className="social_icons">
                                <div className='facebook'></div>
                                <div className='twitter'></div>
                                <div className='linkedinn'></div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 col-12'>
                        <div className='footer_content'>
                            <div>Location: Teerth Technospace,Baner, Pune</div>
                            <div>Contact: +91 7378757330</div>
                            <div>Email: <a href="mailto:wecare@coachx.live">wecare@coachx.live</a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bottombar-container'>
                <div className='desktop-view m-0  sub-container-bottom '>
                    <div className="">
                        <div className='registration_closes'>
                            <div></div>
                            <div>Registration closes</div>
                        </div>
                        <div className='registration_remaining_time'>
                            {props.timeLeft?.day} days : {props.timeLeft?.hour} hours 
                            {/* props.timeLeft?.day} days : {props.timeLeft?.hour} hours : {props.timeLeft?.minute} mins : {props.timeLeft?.second} secs */}
                        </div>
                    </div>
                    <div className="border-left-seperator"></div>
                    <div  >
                        <div className='costTitle'>
                            Cost
                        </div>
                        <div className='actualCost'>
                            {props.data.discount === 0 &&
                                <>
                                    <div className="m-0"></div>
                                    <div> {props.data.fees==0? "Free":"₹"+props.data.fees}</div>
                                </>
                            }
                            {props.data.discount > 0 &&
                                <>
                                   {props.data.fees >0 &&  <div style={{ marginRight: "25px" }}><s>₹ {props.data.fees}</s></div>}
                                    <div>{offerPrize=="0" ? "Free":"₹"+offerPrize}</div>
                                </>}
                        </div>
                    </div>
                    <div className="border-left-seperator"></div>
                    <div  className='display-flex ' >
                        <div className='batch_schedule'>
                            <div className='batch'>
                                <div></div>
                                <div>Batch Starts</div>
                            </div>
                            <div className='batch_start_time'>
                                {props.batchStartDateTime}
                            </div>
                        </div>
                        <div className='book_course_btn'>
                            {/* {(!props?.seatsAvailability || (new Date(props.batchStartDate) < new Date())) &&
                                <button type='button' className='book-course-btn font-family-Helvetica  disable' >Book Course</button>
                            } */}
                            {props?.seatsAvailability && (moment(props.batchStartDate) > moment()) ?
                                <button type='button' className="font-family-Helvetica" onClick={props.signInModel}>Book Course</button>
                                :
                                <button type='button' className='book-course-btn font-family-Helvetica  disable' >Book Course</button>
                            }
                        </div>
                    </div>
                </div>

                <Row className='m-0 mobile-view'>
                    {/* <Col xs="12" md="4" className='display-flex'>
                        <div className='registration_closes'>
                            <div></div>
                            <div>Registration closes</div>
                        </div>
                        <div className='registration_remaining_time'>
                            <span style={{ display: "block" }}>{props.timeLeft?.day} days : {props.timeLeft?.hour} hours</span>
                            
                            <span style={{ display: "block" }}>{props.timeLeft?.day} days : {props.timeLeft?.hour} hours : {props.timeLeft?.minute} </span>
                            <span>mins : {props.timeLeft?.second} secs</span>
                        </div>
                    </Col> */}
                    {/* <Col xs="12" md="3" className='display-flex mt-10 ms-auto'>
                        <div className='costTitle'>
                            Cost
                        </div>
                        <div className='actualCost'>
                            {props.data.discount === 0 &&
                                <>
                                    <div className="m-0"></div>
                                    <div>₹ {props.data.fees==0? "Free":"₹"+props.data.fees}</div>
                                </>
                            }
                            {props.data.discount > 0 &&
                                <>
                                    {props.data.fees >0 && <div style={{ marginRight: "25px" }}><s>₹ {props.data.fees}</s></div>}
                                    <div>{ offerPrize=="0" ? "Free" :"₹"+offerPrize}</div>
                                </>}
                        </div>
                    </Col> */}
                    <Col xs="12" md="5" className='display-flex flex-direction-column'>
                        {/* <div className='batch_schedule display-flex'>
                            <div className='batch'>
                                <div></div>
                                <div>Batch Starts</div>
                            </div>
                            <div className='batch_start_time'>
                                {props.batchStartDateTime}
                            </div>
                        </div> */}
                        <div className='book_course_btn'>
                            {/* {(!props?.seatsAvailability || (new Date(props.batchStartDate) < new Date())) &&
                                <button type='button' className='book-course-btn font-family-Helvetica disable '>Book Course</button>
                            } */}
                            {props?.seatsAvailability && (moment(props.batchStartDate) > moment()) ? 
                                <button type='button' onClick={props.signInModel}>Book Course</button>
                                :
                                <button type='button' className='book-course-btn font-family-Helvetica disable '>Book Course</button>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
             <Modal show={showTerms} onHide={() => { setShowTerms(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Privacy Policies, Terms & Conditions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3 class="privacy-heading">Terms and conditions</h3>
                    <p class="">At CoachX we take 30% service fees on every enrollments happening through this platform. Prices are controlled by teachers/trainers who have listed their programs.
                        We charge no additional cost to our learners who enroll through courses listed on CoachX platform.
                        Teachers design their own curriculum, however CoachX may help to define and implement standard methodologies if needed. Live classes happen only through this platform.
                        We interview the trainers based on their skills, experience, knowledge of teaching online, offline or both. Trainers can conduct short-term workshops with minimal fees and also long term complete courses with the right amount of fees. They have complete
                        control on the pricing they wish to charge. Trainers payments will be released by monthly after settling out any cancellations/ refunds based on learners request.
                    </p>
                    <h4 class="privacy-heading "><u>For any queries kindly email us at wecare@coachx.live</u></h4>
                    <h4 class="privacy-heading">Cancellation and Refund Policies</h4>
                    <h4 class="privacy-heading">A) COMPANY REFUND POLICY IN CASE OF:</h4>
                    <p class="">
                        <h4>1) For Workshops:</h4>
                        Cancellation received at least 24 hours before the scheduled class is refundable or adjusted for upcoming classes, refund process normally takes 5-7 days and cancel request received in less than the 24 hours of scheduled
                        class is not refundable but can be adjustable for any new workshop.
                        <p class="">
                            <h4>2) For Full Course:</h4>
                            Due to the limited batch sizes, we request users that you cancel at least 48 hours before a scheduled class/session, this gives us the opportunity to fill the class. You may cancel by phone or online here, if you have
                            to cancel your class before the 48 hours of the class, class is refundable or adjusted for upcoming classes, refund process normally takes 5-7 days and cancel request received in less than the 48 hours of scheduled class is not refundable
                            but can be adjustable for any other class you wish to attend.</p>
                        <h4 class="privacy-heading">B) GOVERNING LAW AND JURISDICTION:</h4>
                        <p>3.1 In the event of any dispute arising between the parties with respect to this Policy, the same shall be referred to the sole arbitrator and the arbitration shall be in accordance with Arbitration and Conciliation Act of 1996. The language of
                            arbitration proceeding shall be English. The seat and place of arbitration shall be Pune, Maharashtra, India and the decision of the Arbitrator shall be final and binding on both parties herein. 3.2 This Policy shall be subject to the
                            exclusive jurisdiction of courts in Pune, India, and shall be governed by the laws of India.
                        </p >
                        <p class="">
                            <h4 class="privacy-heading">Information Collection and Use</h4>
                            For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to phone, email, user id, interests, user activity
                            in the app. The information that we request will be retained by us and used as described in this privacy policy.</p>
                        <p class="">
                            <h4 class="privacy-heading">Log Data</h4>
                            We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such
                            as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</p>
                        <p class="">
                            <h4 class="privacy-heading">Cookies</h4>
                            Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory. This Service
                            does not use these “cookies” explicitly.However, the app may use third party code and libraries that use “cookies” to collect information and improve their services.You have the option to either accept or refuse these cookies and know when
                            a cookie is being sent to your device.If you choose to refuse our cookies, you may not be able to use some portions of this Service.
                        </p >

                        <p class="">
                            <h4 class="privacy-heading">Service Providers</h4>
                            We may employ third-party companies and individuals due to the following reasons:
                            <ul>
                                <li>To facilitate our Service;</li>
                                <li>To provide the Service on our behalf;</li>
                                <li>To perform Service-related services; or</li>
                                <li>To assist us in analyzing how our Service is used.</li>
                            </ul>
                            We want to inform users of this Service that these third parties have access to your Personal Information.The reason is to perform the tasks assigned to them on our behalf.However, they are obligated not to disclose or use the information for any other
                            purpose.</p >

                        <p class="">
                            <h4 class="privacy-heading">Security</h4>
                            We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic
                            storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

                        <p class="">
                            <h4 class="privacy-heading">Links to Other Sites</h4>
                            This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to
                            review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
                        <p class="">
                            <h4 class="privacy-heading">Children</h4>
                            Our website, apps, other products or services are not directed at children under the age of 13 and we endeavor not to knowingly collect any Personally Identifiable Information from children under the age of 13.
                            If you are under the age of 13, we request that you do not provide us with your information and do not use our websites and apps.
                            If you are aged 13, 14 or 15, we request that you seek your parent or guardians permission before providing us with your information or using our websites or apps</p >

                        <p class="">
                            <h4 class="privacy-heading">Changes to This Privacy Policy</h4>
                            We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this
                            page.
                            This policy is effective as of<u>2021 - 10 - 10</u ></p ></p >
                    <p class="">
                        <h4 class="privacy-heading">Contact Us</h4>
                        If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at wecare@coachx.live</p>
                </Modal.Body>
            </Modal >

        </>
    );
}

export default BottomBar;