import React from 'react'
import ReactStars from "react-rating-stars-component";
import { Row, Col, Button } from 'react-bootstrap';

export default function CourseFeedbackItem(props) {
    return (
        <div className="course-feedback-item-wrapper">

            <div className="course-feedback-item-title" style={{ margin: "10px 0" }}>
                <img className="course-feedback-item-image" src={props.data.stu_info.profile_pic} alt="icon"></img> 
                <span className='course-feedback-item-name'>{props.data.stu_info.user_info.first_name}</span>
            </div>
            <div className="course-feedback-item-desc" style={{ margin: "10px 0" }}>
                <span>{props.data.review}</span>
            </div>
            <div style={{ margin: "10px 0" }}>
                <Row>

                    <Col md={12} className="course-feedback-stars">
                        <ReactStars
                            count={5}
                            size={30}
                            value={Math.round(props.data.rating) }
                            activeColor="#FFC70D"
                            edit={false}
                        />
                        <span className='font-family-Helvetica' style={{marginLeft:"10px"}}>{Math.round(props.data.rating)}/5 stars</span>
                    </Col>
                </Row>
            </div>
        </div>
    )
}