import React from 'react';
import { BrowserRouter as Router, Route,Routes  } from 'react-router-dom';

import Dashboard from './DashboardLayout'
import Home from './Home'

const RouterComp =() =>
{
    return (
            <Router>            
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="course/" element={<Dashboard />}/> 
                    {/* <Redirect to="/dashboard" />*/}
                </Routes>
            </Router>
    );
}

export default RouterComp;